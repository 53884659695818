/**
 * Stylesheet fuer Seitenblick.de
 *
 * Seitenblick

 * e-Mail: info@Seitenblick.de
 */


/*------------------------------------*\
    #BOOTSTRAP
\*------------------------------------*/

//@import '../libs/bootstrap-sass/bootstrap.scss';
@import '~bootstrap/scss/bootstrap';

html.placeholder {
  background-color: transparent;
  opacity: 1;
  cursor: auto;
}




/*------------------------------------*\
    #COLORS
\*------------------------------------*/
$border-color: #e8e8e8;
$table-border-color: #b0c5d8;
$primary-color: #435b7d;
$secondary-color: #97c00e;
$light-color: #fff;
$light-bg: #f4f5f7;

.primary-bg{
  background-color: $primary-color;
  color: $light-color;
  a{
    color: $light-color;
  }
}

.secondary-color{
  color: $secondary-color;
}

.light-bg{
  background-color: $light-bg;
}





/*------------------------------------*\
    #ALLGEMEIN
\*------------------------------------*/
body{
  color: $primary-color;
  //font-family: Droid Sans, sans-serif;
  font-family: droid-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
}

#main{
  //margin-top: 120px;
  padding-top: 120px;
}

header{
  left: 0;
  min-height: 70px;
  padding-top: 15px;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
}

.nested {
  z-index: 100;
}

.logo-wrapper{
  position: relative;
  top: -6px;
  /*right: -30px;*/
}

.logo{
  float: right;
}

#btn-next polygon{
  fill: #97c00e;
  -webkit-transition: fill 1s ease;
  transition: fill 1s ease;
}
@media only screen and (max-width:544px) {
  .logo-wrapper{
    top: -1px;
  }
  .logo{
    width: 110px;
  }
}

.bg-color-1{
  background-color: $light-bg;
}

.contentelement{
  font-size: 1.125rem;
  line-height: 1.875rem;
  padding-top: 50px;
  padding-bottom: 50px;
}

a{
  color: #638cac;
}

.img-fluid{
  margin: auto;
}

img[data-sizes="auto"] { display: block; width: 100%; }

/*------------------------------------*\
    #HEADLINES
\*------------------------------------*/
h3{
  font-size: 1.3rem;
  font-weight: 700;
}





.kompetenz{
  .contentelement{
    border-bottom: 1px solid $border-color;
    margin-bottom: 30px;
    padding-bottom: 80px;
  }
  .img-circle{
    margin-bottom: 30px;
  }
  @media only screen and (min-width:768px) {  
    .img-circle{
      margin-bottom: 0;
    }
  }
}

.referenzen{
  >div:last-child{
    margin-bottom: 40px;
  }
  >div:first-child{
    /*border-top: 2px solid $border-color;*/
    /*margin-top: 40px;*/
  }
  .contentelement{
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .contentelement.no-pb{
    padding-bottom: 0;
  }
  .bg-color{
    .contentelement{
      padding-bottom: 45px;
      padding-top: 45px;
    }
  }
  cite{
    color: #638cac;
    display: block;
    font-size: 1.375rem;
    margin-bottom: 1rem;
    font-style: normal;
    line-height: 2rem;
    p:before{
      content: "\201E";
    }
    p:after{
      content: "\201D";
    }
  }
  .cite-author{
    font-weight: 700;
  }
}

.otherprojects{
  .contentelement{
    border-top: 1px solid #c6d3e6;
    position: relative;
    &:before{
      border-top: 1px solid #638cac;
      content:"";
      height: 100%;
      left: 0;
      position: absolute;
      top: -2px;
      width: 100%;
    }
    .item{
      margin-bottom: 30px;
    }
  }
}

.textlink{
  margin-top: 1.5rem;
  &:before{
    color: $secondary-color;
    content: "\203a";
    display: inline-block;
    font-weight: 700;
    padding-right: 6px;
  }
}

.backlink{
  &:before{
    color: $secondary-color;
    content: "\2039";
    display: inline-block;
    font-weight: 700;
    padding-right: 6px;
  }
}




/*------------------------------------*\
    #NAVIGATION
\*------------------------------------*/

.nav_overlay{
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.nav_overlay > div{
  height: 100%;
  padding: 0;
}

.nav_colorfill{
  background: $secondary-color;
  height: 100%;
  left: -1000%;
  position: absolute;
  top:0;
  width: 1000%;
}

.slide-navi {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  position: fixed;
}

.slide-navi .menu{
  margin: 0;
}

.slide-navi .menu.servicelinks {
  line-height: 20px!important;
  margin-bottom: 20px;
  font-size: 14px;
}


/*Hamburger - Cross*/
.nav-icon-wrapper.fixed {
  position: fixed;
}
#nav-icon{
  background: rgba(255,255,255,0.4);
  border: 8px solid transparent;
  z-index: 1001;
  width: 40px;
  height: 40px;
  position: relative;
  //left: -20px;
  margin: 10px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $secondary-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .nav-icon-text{
    position: absolute;
    left: 40px;
    top: 3px;
  }
}

#nav-icon.open{
  span{
    background-color: #fff!important;
  }
}



#nav-icon span:nth-child(1) {
  top: 2px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 10px;
}

#nav-icon span:nth-child(4) {
  top: 18px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/*Menu*/
.menu {
  list-style: none;
  line-height: 42px;
  margin: auto;
  padding-left: 0;
  width: 13em;
}

.menu a {
  /*background: url(icons.png) no-repeat left top;*/
  color: #ddd;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  padding-left: 3em;
  width: 100%;
}

.menu a:hover {
  margin-left: 1em;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.menu li {
  margin-bottom: 5px;
  padding-left: .5em;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

/*SidebarNav*/
nav {
  background: $secondary-color;
  box-sizing: border-box;
  padding-right: .25em;
  position: absolute;
  height: 100%;
  left: -300px;/*-235px;*/
  padding-top: 5em;
  top: 0;
  z-index: 20;
  -webkit-transition: all .6s;
  -o-transition: all .6s;
  transition: all .6s;
}

nav.active{
  pointer-events: auto;
}


@media only screen {
  .container{
    nav.active {
      left: 0;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}
@media only screen and (min-width:544px) {
  .container{
    $container-width: 520px;
    nav.active {
      left: calc((100% - #{$container-width})/2);
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}
@media only screen and (min-width:768px) {
  .container{
    $container-width: 740px;
    nav.active {
      left: calc((100% - #{$container-width})/2);
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}
@media only screen and (min-width:992px) {
  .container{
    $container-width: 940px;
    nav.active {
      left: calc((100% - #{$container-width})/2);
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}
@media only screen and (min-width:1200px) {
  .container{
    $container-width: 1140px;
    nav.active {
      left: calc((100% - #{$container-width})/2);
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}





/*------------------------------------*\
    #STARTSEITE
\*------------------------------------*/
html.home,
.home body{
  height: 100%;
  overflow: hidden;
}

footer{
  svg.logo{
    path,
    rect,
    polygon{
      fill:#fff;
      -webkit-transition: fill 1s;
      transition: fill 1s;
    }
  }
}



article.project{
  height: 100%;
  position: absolute;
  width: 100%;
  &.is-active{
    z-index: 1;
  }
}

#btn-next{
  background: rgba(255, 255, 255, 0.4) none repeat scroll 0% 0%;
  border-radius: 50%;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  z-index: 5;
}
#btn-next:active,
#btn-next:focus{
  outline: none;
}

.project-col{
  width: 50%;
}
.project-right-col{
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
}
.project-left-col{
  height: 100%;
}

@media only screen and (max-width:992px) {
  .project-col{
    width: 100%;
  }
  .project-right-col,
  .project-left-col{
    height: 50%;
    left: 0;
  }
  .project-right-col{
    top: 50%;
  }
}

.project-content{
  height: 100%;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
  transform: translate(0%, -200%) matrix(1, 0, 0, 1, 0, 0);
  .table{
    display: table;
    width: 100%;
    height: 100%;
  }
  .tablecell{
    display: table-cell;
    padding: 0 15%;
    vertical-align: middle;
  }
  .project-kicker,
  .project-title{
    text-align: left;
    -webkit-transition: color 1s;
    transition: color 1s;
  }
  .project-title{
    font-size: 4rem;
    font-size: 6vmax;
  }
  .project-kicker{
    font-size: 2rem;
    font-size: 3vmax;
    font-weight: 300;
  }
  .project-link{
    background: #fff;
    color: #000;
    display: inline-block;
    margin-top: 10px;
    padding: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .project-text{
    -webkit-transition: color 1s;
    transition: color 1s;
  }

@media only screen and (min-width:1200px) {
  .project-title{
    font-size: 4rem;
  }
  .project-kicker{
    font-size: 2rem;
  }
}

  .btn{
    -webkit-transition: color 1s;
    transition: color 1s;
  }
}

.project-image{
  transform: translate(0%, -200%) matrix(1, 0, 0, 1, 0, 0);
  height: 100%;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.project-image-copyright{
  bottom: 10px;
  color: #fff;
  font-size: 1vmax;
  left: 10px;
  position: absolute;
}

/*Bullet-Navi Startseite*/
.bullet-nav{
  color: #fff;
  position: absolute;
  top: 200px;
  visibility: hidden;
  z-index:6;
}

.nav-list{
  display: none;
  list-style: none;
}

.nav-item{
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0);
  display: block;
  height: 16px;
  margin: 16px;
  position: relative;
  transition: box-shadow 0.3s ease 0s;
  -webkit-transition: color 1s;
  transition: color 1s;
  width: 16px;
}

.nav-item.is-active{
  box-shadow: 0 0 0 1px;
  transform: scale(1.4);
  transition: transform 1s;
}

.nav-item a{
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
  width: 100%;
}

.nav-item.is-active a{
  transform: scale(0.6);
}




/*------------------------------------*\
    #ICONS
\*------------------------------------*/
.icon-competence{
  margin-top: 0;
}
/*
@media only screen and (min-width:544px) {
  .icon-competence{
    margin-top: 30px;
  }
}
*/

.flex .logoicon{
  margin-top: 40px;
  margin-left: 20px;
}





/*------------------------------------*\
    #FLEX
\*------------------------------------*/
.flex{
  display: flex;
}




/*------------------------------------*\
    #VCARD
\*------------------------------------*/
.vcard{
  position: absolute;
  left: 35px;
  bottom: 50px;
  .secondary-color{
    padding-right: 15px;
  }
}





/*------------------------------------*\
    #FORMULAR
\*------------------------------------*/
.formgenerator{
  margin: 15px 0;
}

.has-error {
  color: #ff0000;
}
.form-control{
  border: 0;
  border-radius: 0;
}
button[type="submit"] {
  border: 1px solid $secondary-color;
  border-radius: 0;
  color: $primary-color;
  float: right;
  position: relative;
  bottom: 10px;
}

.label-success,
.label-danger{
  color: $light-color;
  margin-top: 10px;
  padding: 10px;
}
 /*Honeypot*/
#notes {
  display: none;
}




/*------------------------------------*\
    #TABELLEN
\*------------------------------------*/
.table{
  margin-bottom: 0;
  tr{
    &:first-child{
      td{
        border-top: none;
      }
    }
    td{
      border-top: 2px solid $table-border-color;
      font-weight: 700;
      &:first-child{
        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }
}





/*------------------------------------*\
    #ACCORDION
\*------------------------------------*/
.sb-panel-group {
  .panel {
    border: 1px solid $border-color;
    box-shadow: none;
    margin-bottom: 5px;
    .panel-heading {      
      border-radius: 0;
      padding: 0px;
      position: relative;
      .panel-title {
        background: $light-bg;
        color: $primary-color;
        display: block;        
        font-size: 1.25rem;
        font-weight: 400;
        padding: 10px 45px 10px 15px;
        outline: 0;
        text-decoration: none;
        &:after {
         background: $light-color;
         border: 1px solid $primary-color;
         content: "+";
         font-size: 1.875rem;        
         height: 30px;         
         line-height: 17px;
         padding: 6px 4px 0 4px;
         position: absolute;
         text-align: center;
         right: 10px;
         top: 10px;
         width: 30px;
        }
        &:focus {
          border: 0;
        }
        &.collapsed{
          background: $light-bg;
        }
      }
      [aria-expanded="true"] {
        &:after {
         content:"-";
         padding-top: 3px;
        }
      }
    }
    .panel-body {
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 5px 12px 10px 12px;
      h3 {
        margin: 20px 0 10px 0;
        min-height: auto;
      }
      ul {        
        margin: 0;        
        li{
          color: $primary-color;
          padding: 0 0 0 13px;
          position: relative;          
        }
      }
      .text{
        padding: 15px;
      }
      .swapcontact {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
  }
}
.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 0!important;
}

.caption{
  font-size: 0.875rem;
}





/*------------------------------------*\
    #REFERENZEN LISTE
\*------------------------------------*/
.reference{
  margin-top: 20px;
  padding: 0!important;
}

.item{
  margin-bottom: 20px;
  position: relative;
}

.projecttitle{
  background: #fff;
  bottom: 20px;
  position: absolute;
  left: 0;
  padding: 5px 20px;
}

.hoverlayer{
  background-color: transparent;
  color: transparent;
  height: 100%;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  width: 100%;
  div{
    font-size: 1rem;
    left: 10%;
    position: relative;
    width: 90%;
  }
  .year{
    padding-top: 10%;
  }
  .client{
    font-size: 1.4rem;
    line-height: 1.25rem;
    padding-top: 12%;
  }
  .claim{
    padding-top: 2%;
  }
}
@media only screen and (min-width: 769px) {
  .is-expanded{
    .hoverlayer{
      div{
        font-size: 1.5rem;
      }
      .client{
        font-size: 2.4rem;
      }
    }
  }
}
@media only screen and (min-width:544px) {
  .item{
    margin-bottom: 0;
  }

  .flexcontainer{
    display: flex;
    flex-flow: row;
    margin-bottom: 2%;
    .left{
      flex: 814;
      margin-right: 2%;
      min-height: 300px;
    }
    .right{
      display: flex;
      flex: 395;
      flex-direction: column;
      /*max-width: 30%;*/
      position: relative;
      .top{
        position: relative;
        top: 0;
      }
      .bottom{
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
    img{
      width: 100%;
    }
  }

  .flexcontainer_3col{
    display: flex;
    flex-flow: row;
    min-height: auto;
    -webkit-justify-content: space-around; /* Safari */
    justify-content:         space-around;
    div{
      flex: 1;
      /*width: 32%;*/
    }
    div:nth-child(2):not(.projecttitle){
       margin: 0 2%;
    }
    img{
      width: 100%;
    }
  }

}




/*------------------------------------*\
    #REFERENZEN DETAIL
\*------------------------------------*/
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
      margin: auto;
    }
    &.slick-loading img {
      display: none;
    }

    display: none;

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
  display: none;
}

/*Slider-Theme*/
@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
  background: #fff url('/img/ajax-loader.gif') center center no-repeat;
}


/* Icons */
/*
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/slick.eot');
    src: url('/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/fonts/slick.woff') format('woff'), url('/fonts/slick.ttf') format('truetype'), url('/fonts/slick.svg#slick') format('svg');
}
*/
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 40px;
    height: 50px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-size: 50px;
    line-height: 25px;

    opacity: .75;
    color: $primary-color;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: "\2039";
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: "\203a";
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -50px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 15px;
    height: 15px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 15px;
    height: 15px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
  border-radius: 50%;
  background-color: $primary-color;
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;

    content: ' ';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}







/*------------------------------------*\
    #MASONRY für REFERENZLISTE
\*------------------------------------*/
#masonryContainer{
  max-width: 100%;
  padding: 0 15px;
}
@media only screen and (min-width: 1200px) {
  .reference{
    padding: 0 15px;
  }
}
.masonry-filter{
  position: relative;
  padding: 15px;
  z-index: 5;
  li{
    cursor: pointer;
    display: inline;
    padding-right: 30px;
    &.selected{
      font-weight: 700;
    }
  }
}

.grid {
  max-width: 100%;
  margin: 0 auto;
}

.grid-item {
  float: left;
  margin-bottom: 20px;
  img{
  /*border: 1px solid #435b7d;*/
    width: 100%;
  }
}


/* item is invisible, but used for layout */
.grid-item,
.grid-item-content {
  width: 360px;
  height: 237px;
  &:hover{
    .hoverlayer{
      color: #fff;
      background-color: rgba(255,0,0,.75);
      transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -webkit-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
    }
  }
}

@media only screen and (max-width: 767px) {
  .grid-item,
  .grid-item-content{
    right: 15px;
    width:360px;
    max-width: 91%;
    height: auto!important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .grid-item,
  .grid-item-content{
    right: 15px;
    width:360px;    
    max-width: 100%;
    height: auto!important;
  }
  .reference{
    position: relative;
    left: -15px;
  }
}

/* grid-item-content is visible, and transitions size */
.grid-item-content {
  width: 360px;
  height: 237px;
  -webkit-transition: width 0.4s, height 0.4s;
          transition: width 0.4s, height 0.4s;
}

.grid-item:hover .grid-item-content {
  border-color: white;
  background: #A2C;
  cursor: pointer;
}

/* both item and item content change size */
.grid-item.is-expanded,
.grid-item.is-expanded .grid-item-content {
  height: 494px;
  width: 740px;
}
@media only screen and (max-width: 768px) {
  .grid-item.is-expanded,
  .grid-item.is-expanded .grid-item-content {
    /*min-height: 237px;*/
    height: auto;
    width: 100%;
  }
}

/*
.grid-item.is-expanded {
  z-index: 2;
}


.grid-item.is-expanded .grid-item-content {
  background: #F90;
}
*/

.is-expanded {
  img {
    height: 494px;
    width: 740px;
  }
}
@media only screen and (max-width:768px) {
  .is-expanded {
    img {
      height: 237px;
      width: 360px;
    }
  }
}




/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
footer{
  background-color: $primary-color;
  color: $light-color;
  font-size: 0.875rem;
  margin-top: 50px;
  padding: 40px;
  a{
   color: $light-color;
   &:hover,
   &:focus{
     color: $table-border-color;
   }
  }
  .metanavi{
    text-align: right;
    a{
      display: block;
    }
  }
}


/*------------------------------------*\
    #NEWSLETTER DETAIL
\*------------------------------------*/
@media only screen and (min-width: 768px) {
  .image-wrapper.float-sm-start {
    margin-right: 40px;
  }
}
.gallery {
  .slick-slide {
    img {
      padding: 10px;
    }
  }
}
.module_gallery {
  .slick-prev {
    left: -35px;
  }
  .slick-next {
    right: -35px;
  }
}


/*------------------------------------*\
    #COOKIE HINWEIS
\*------------------------------------*/
.cc_container .cc_btn {
background-color: #97c00e!important; /* Farbe des Buttons */
color: #435b7d!important; /* Textfarbe des Buttons */
}
.cc_container .cc_btn:hover {
background-color: #fff!important; /* Farbe des Buttons */
color: #000!important; /* Textfarbe des Buttons */
}
.cc_container {
background: #222!important; /* Hintergrundfarbe des gesamten Bereichs */
}
.cc_container p{
  color: #fff!important; /* Schriftfarbe des gesamten Bereichs */
}
.cc_container a {
color: #97c00e!important; /* Textlink-Farbe "Mehr Infos" */
}
.cc_container a:hover {
color: #fff!important; /* Textlink-Farbe "Mehr Infos" */
}